table.stickyTable{
    text-align: left;
    position: relative;
    border-collapse: separate;     
}
table.stickyTable th {
    position: sticky;
    top: -1px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
tr.selectedRow td{
    border-top: solid 2px #4f4e4d;
    border-bottom: solid 2px #4f4e4d;    
}
tr.selectedRow td:first-child{          
    border-left: solid 2px #4f4e4d;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
tr.selectedRow td:last-child{
    border-right: solid 2px #4f4e4d;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}